// let localHost =     'http://localhost:4000/dev';
// let liveHost =      'http://localhost:4000/dev';

// let localHost =     'https://yellapi.bigg.co.uk/v1';
// let liveHost =      'https://yellapi.bigg.co.uk/v1';

let localHost =     'https://thomsonapi.bigg.kodie.co.uk/v1';
let liveHost =      'https://thomsonapi.bigg.kodie.co.uk/v1';

export const SiteConfig = {
    feedWidth:       690,
    bannerWidth:    1095,
    bannerHeight:   405,
    apiKey:         "133asdkjASFFAS3SD3553ASDFFDS982kjdlkasjdak",
    logging:        true,
    //endpoint:   'https://api.crgo.co.uk/v1',
    
    endpoint:   window.location.hostname.includes('localhost') ? localHost : liveHost,
    cookieName: 'tl.bigg',
    logos:      {
        lightLogo:  '/images/yadoologopink.png',
        darkLogo:   '/images/yadoologopink.png',
        alt:        'Yadoo'
    },
    logOut:     {
        units:      10,
        unitType:   'hours'
    }
}

export default SiteConfig;